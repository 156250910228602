import {Component, HostBinding, Input} from '@angular/core';
import {SmartLinkDefinitions, SmartLinkTypeKey} from '../../../../definitions/definitions';
import {SmartLinkDefinition} from '../../../../definitions/definitions-base';
import {HoverPreviewDirective} from '../directives/hover-preview.directive';
import {PageThroughItem} from '../../../slide-panel/slide-panel';

@Component({
    selector: 'app-smart-link',
    host: {'class': 'smart-link'},
    templateUrl: './smart-link.component.html',
})
export class SmartLinkComponent extends HoverPreviewDirective {
    @Input() type: SmartLinkTypeKey;
    @Input() action: () => void;
    @Input() pageThrough: PageThroughItem[];
    @Input() pagingSecondaryId: number | string;
    @Input() customContent = false;
    @Input() queryParams: {[keys: string]: string} = null;
    @HostBinding('class') @Input() extraClasses: string;

    path: string;
    colorClass: string;
    color: string;
    icon: string;
    date: Date | string;
    pre: string;
    post: string;
    label: string;
    tooltip: string;
    link: string;
    fragment: string;
    fade: boolean;
    navigationState: {[k: string]: any};

    async ngOnChanges() {
        const definition: SmartLinkDefinition = SmartLinkDefinitions[this.type];
        if (definition && this.data) {
            this.loadPreviewComponent = definition.loadComponent;
            this.loadPreviewModule = definition.loadChildren;
            if (!this.loadPreviewComponent) this.component = null;
            this.path = definition.path;
            this.colorClass = definition.getColorClass ? definition.getColorClass(this.data) : null;
            this.color = definition.getColor ? definition.getColor(this.data) : null;
            this.icon = definition.getIcon ? definition.getIcon(this.data) : null;
            this.date = definition.getDate ? definition.getDate(this.data) : null;
            this.pre = definition.getPre ? definition.getPre(this.data) : null;
            this.post = definition.getPost ? definition.getPost(this.data) : null;
            this.label = definition.getLabel ? definition.getLabel(this.data) : null;
            this.fade = definition.fade;
            this.openDetailsText = definition.getOpenDetailsText ? definition.getOpenDetailsText(this.data) : 'Open Details';
            this.tooltip = definition.getTooltip ? definition.getTooltip(this.data) : null;
            if (definition.getLink) this.link = definition.getLink(this.data);
            if (definition.getFragment) this.fragment = definition.getFragment(this.data);
            if (this.link && this.pageThrough) {
                this.navigationState = {pageThrough: this.pageThrough};
                if (this.pagingSecondaryId) this.navigationState = {...this.navigationState, pagingSecondaryId: this.pagingSecondaryId};
            }
            this.action = definition.action ?
                () => definition.action(this.data) :
                this.link ?
                    () => {
                        this.router.navigate([this.link], {fragment: this.fragment, ...(this.queryParams ? {queryParams: this.queryParams} : {})});
                        this.destroyComponent();
                    } :
                    null;
        }
    }
}
