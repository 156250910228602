<a  [routerLink]="link || null"
    [fragment]="link && fragment || null"
    [queryParams]="queryParams || null"
    [state]="navigationState"
    (click)="!link && action ? action() : null"
    [style.cursor]="action ? 'pointer' : 'default'"
    [ngbTooltip]="tooltip">
    <ng-container *ngIf="!customContent">
        <fas *ngIf="icon" [i]="icon"></fas>
        <span *ngIf="colorClass" class="status-indicator bg-{{colorClass}} mr-1"></span>
        <span *ngIf="color" class="status-indicator mr-1" [style.background]="color"></span>
        <small *ngIf="pre" class="mr-1">{{pre}}</small>
        <small *ngIf="date" class="mr-1">{{date|simpleDate}}</small>
        {{label}}
        <small *ngIf="post" class="ml-1">{{post}}</small>
    </ng-container>
    <ng-content></ng-content>
</a>
